<template>
  <div class="text-general row">
    <div class="col-sm-4 mb-3">
      <div class="list-group mb-3" role="tablist">
        <a
          href="#"
          v-for="note in notes"
          v-bind:key="note.id"
          v-on:click="switchNote(note.id)"
          class="list-group-item list-group-item-action"
          role="tab"
        >
          {{ note.title }}
        </a>
      </div>
      <div class="list-group" role="tablist">
        <a
          href="#"
          v-on:click="create"
          class="list-group-item list-group-item-action"
          role="tab"
        >
          <i class="fa fa-plus"></i> New note
        </a>
      </div>
    </div>
    <div class="col-sm-8">
      <form v-if="current !== undefined">
        <h2>
          <input type="text" v-model="current.title" style="border: none" />
        </h2>
        <div class="form-group">
          <textarea
            class="form-control"
            v-model="current.content"
            rows="10"
            style="border: none; padding: 0"
          ></textarea>
          <!--<MDE v-model="current.content"/>-->
        </div>
        <a role="button" class="btn btn-outline-primary mr-2" v-on:click="save"
          >Save</a
        >
        <a role="button" class="btn btn-outline-danger" v-on:click="del"
          >Delete</a
        >
        <p class="float-right">Owner: {{ current.owner }}</p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notes",
  data() {
    return {
      notes: [],
      current: undefined,
    };
  },
  methods: {
    refresh() {
      let self = this;
      this.apis.notes.getNotes().then((result) => {
        self.notes = result;
      });
    },
    switchNote(id) {
      this.apis.notes.getNote(id).then((result) => {
        this.current = result;
      });
    },
    save() {
      this.apis.notes
        .updateNote(
          this.current.id,
          this.current.title,
          this.current.content,
          this.current.is_public
        )
        .then(() => {
          this.refresh();
        });
    },
    del() {
      let self = this;
      this.apis.notes.deleteNote(this.current.id).then((result) => {
        if (result) self.current = undefined;
        this.refresh();
      });
    },
    create() {
      this.apis.notes.createNote().then((result) => {
        this.refresh();
        this.current = result;
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
h2 input {
  background-color: transparent;
  border: none;
  padding: 0;
}

h2 input:focus {
  outline: none;
}

textarea {
  background-color: transparent;
  border: none;
  padding: 0;
}

textarea:focus {
  outline: none;
}
</style>
